import http from "../http-common";

class InsuranceDataService {
  
  get() {
    return http.get("/insurance")
  }
  getTop5() {
    return http.get("/insurance/top5")
  }
  changeStatusTo0(id) {
    return http.post("/insurance/changestatusto0/"+id)
  }
  changeStatusTo1(id) {
    return http.post("/insurance/changestatusto1/"+id)
  }
  getReport(month = 0, year = 0) {
    return http.get("/insurance/report", {params:{month: month, year: year}})
  }
}

export default new InsuranceDataService();