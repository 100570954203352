<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <download-excel
              class="btn btn-download-excel"
              :data="excelData"
              :fields="excelFields"
              worksheet="Vendas"
              :name="'sales-'+(new Date().getTime())+'.xls'"
            >
              <img src="icon-excel.svg"> Export Excel
            </download-excel>
            Vendas
          </CCardHeader>
          <CCardBody>
            
            <CDataTable
              class="mb-0 table-outline"
              hover
              :items="tableInsurances"
              :fields="tableFields"
              head-color="light"
              sorter
              :items-per-page="10"
              pagination
              table-filter
            >
              <td slot="id" class="text-center" slot-scope="{item}" :columnFilter='{ external: true, lazy: true }'>
                <div>
                  {{item.id}}
                </div>
              </td>
              <td slot="order_code" class="text-center" slot-scope="{item}" :columnFilter='{ external: true, lazy: true }'>
                <div>
                  {{item.order_code}}
                </div>
              </td>
              <td slot="plano" slot-scope="{item}">
                <div>{{item.plan}}</div>
              </td>
              <td slot="price" slot-scope="{item}">
                <div>{{item.price}} €</div>
              </td>
              <td slot="payment_status" slot-scope="{item}" :columnFilter='{ external: true, lazy: true }'>
                <CBadge :color="getBadge(item.payment_status)">{{item.payment_status}}</CBadge>
              </td>

              <td slot="action" slot-scope="{item}">
                <div v-if="item.status == 0">
                  <div class="text-center">
                    <CButton block variant="outline" color="danger" style="width: 129px" @click="alterStatusTo1(item.id)">
                      <CIcon name="cil-ban" width="15"/> Não Enviada
                    </CButton>
                  </div>
                </div>
                <div v-if="item.status == 1">
                  <div class="text-center">
                    <CButton block variant="outline" color="success" style="width: 129px" @click="alterStatusTo0(item.id)">
                      <CIcon name="cil-check" width="15"/> Enviada
                    </CButton>
                  </div>
                </div>
              </td>
              
              <td slot="detail" slot-scope="{item}">
                <CButton block variant="outline" color="primary" @click="getDados(item.id)">Ver</CButton>
              </td>
              
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <CModal
      title="Seguro"
      size="lg"
      :show.sync="largeModal"
      
    >
      <CRow v-for="insurances in this.modalInsurances" :key="insurances">
        <CCol sm="12">
          <CCard>
            <CCardHeader>
              <strong>Informação </strong>
            </CCardHeader>
            <CCardBody>
              <div class="row">
                <div class="col-md-4">
                  <label>Número:</label>
                  <p>{{insurances.id}}</p>
                </div>
                <div class="col-md-4">
                  <label>Plano:</label>
                  <p>{{insurances.plan}}</p>
                </div>
                <div class="col-md-4">
                  <label>Pagamento:</label>
                  <p>
                    <CBadge :color="getBadge(insurances.payment_status)">{{insurances.payment_status}}</CBadge>
                  </p>
                </div>

                <div class="col-md-4">
                  <label>Data de subscrição:</label>
                  <p>{{new Date(insurances.createdAt).toLocaleString('pt-PT')}}</p>
                </div>

                <div class="col-md-4">
                  <label>Data inicial:</label>
                  <p>{{new Date(insurances.initial_date).toLocaleDateString('pt-PT')}}</p>
                </div>

                <div class="col-md-4">
                  <label>Data final:</label>
                  <p>{{new Date(insurances.final_date).toLocaleDateString('pt-PT')}}</p>
                </div>

                <div class="col-md-12">
                  <label>Países:</label>
                  <p>{{insurances.countries}}</p>
                </div>

                <div class="col-md-12">
                  <label>Método de Pagamento:</label>
                  <p>{{insurances.payment_method}}</p>
                </div>

                <div class="col-md-8">
                  <label>Easypay payment id:</label>
                  <p>{{insurances.easypay_payment_id}}</p>
                </div>

                <div class="col-md-4">
                  <label>Código de compra:</label>
                  <p>{{insurances.order_code}}</p>
                </div>

                <div class="col-md-6">
                  <label>Apólice enviada:</label>
                  <div>
                    {{insurances.status == 0? 'Não' : 'Sim'}}
                  </div>
                </div>
              </div>
              
            </CCardBody>
          </CCard>
        </CCol>

        <CCol sm="12">
          <CCard>
            <CCardHeader>
              <strong>Preço:</strong>
            </CCardHeader>
            <CCardBody>
              <div class="row">
                <div class="col-md-4">
                  <label>Total:</label>
                  <h4>{{insurances.price}} €</h4>
                </div>
                <div class="col-md-4">
                  <label>Desconto:</label>
                  <p v-if="insurances.promotional">
                    {{insurances.promotional.discount}} %
                  </p>
                  <p v-else>
                    0%
                  </p>
                </div>
                <div class="col-md-4">
                  <label>Código promocional:</label>
                  <p v-if="insurances.promotional">
                    {{insurances.promotional.code}} (id: {{insurances.promotional.id}})
                  </p>
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>

        <CCol sm="12">
          <CCard  v-for="(person, index )  in insurances.persons" :key="index">
            <CCardHeader>
              <strong>Pessoa </strong>{{index +1}} 
              <strong v-if="person.tomador">| Tomador </strong>
              <strong v-if="person.segurado">| Segurado </strong>
            </CCardHeader>
            <CCardBody>
              <div class="row">
                <div class="col-md-4">
                  <label>Nome:</label>
                  <p>{{person.name}}</p>
                </div>
                <div class="col-md-4">
                  <label>Sobrenome:</label>
                  <p>{{person.surname}}</p>
                </div>
                <div class="col-md-4">
                  <label>NIF:</label>
                  <p>{{person.nif}}</p>
                </div>
                 <div class="col-md-4">
                  <label>Cartão Cidadão:</label>
                  <p>{{toUpper(person.cc)}}</p>
                </div>
                <div class="col-md-4">
                  <label>Bilhete de Identidade:</label>
                  <p>{{person.bi}}</p>
                </div>
                <div class="col-md-4">
                  <label>Passaporte:</label>
                  <p>{{person.passport}} - {{person.passport_country}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <label>Data de Nascimento:</label>
                  <p>{{new Date(person.date_of_birth).toLocaleDateString('pt-PT')}}</p>
                </div>
                <div class="col-md-4">
                  <label>Telemóvel:</label>
                  <p>{{person.mobile_number}}</p>
                </div>
                <div class="col-md-4">
                  <label>Email:</label>
                  <p>{{person.email}}</p>
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>

        <CCol sm="12">
          <CCard v-for="(adress, index )  in insurances.addresses" :key="index">
            <CCardHeader>
              <strong>Endereço </strong>
            </CCardHeader>
            <CCardBody>
              <div class="row">
                <div class="col-md-4">
                  <label>Cidade:</label>
                  <p>{{adress.city}}</p>
                </div>
                <div class="col-md-4">
                  <label>País:</label>
                  <p>{{adress.country}}</p>
                </div>
                <div class="col-md-4">
                  <label>Código postal:</label>
                  <p>{{adress.postal_code}}</p>
                </div>
                <div class="col-md-6">
                  <label>Localização:</label>
                  <p>{{adress.location}}</p>
                </div>
                <div class="col-md-6">
                  <label>Endereço:</label>
                  <p>{{adress.line_1}}</p>
                </div>
              </div>
              
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <template #footer>
        <CButton @click="largeModal = false" color="success">Ok</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import InsuranceDataService from "@/services/InsuranceDataService";

export default {
  name: 'Sales',
  data () {
    return {
      selected: 'Month',
      tableInsurances: [],
      tableFields: [
        { key: 'id', label: 'Num. Reserva', _classes: 'text-center', sorter: 1, excel: true },
        { key: 'order_code', label: 'Código de compra', excel: true},
        { key: 'plano', label:'Plano', excel: true },
        { key: 'price', label:'Preço', excel: true },
        { key: 'payment_status', label:'Pagamento', excel: true },
        { key: 'action', label:'Apólice' },
        { key: 'detail', label:'Detalhes' }
      ],
      largeModal: false,
      modalInsurances: '',
    }
  },
  computed: {
    excelFields() {
      let fields = {
        "Num. Reserva": "id",
        "Código da Compra": "order_code",
        "Data de subscrição": "created_at",
        Nome: "name",
        Sobrenome: "surname",
        "E-mail": "email",
        Telemóvel: "mobile_number",
        NIF: "nif",
        Países: "countries",
        "Data inicial": "initial_date",
        "Data Final": "final_date",
        "Qt. Pessoas": "people_quantity",
        Plano: "plan",
        Status: "status",
        "Método Pagto.": "payment_method",
        "Status Pagto.": "payment_status",
        Preço: "price",
        "Código de Desconto": "promotionalcode",
        Desconto: "discount",
      };
      return fields;
    },
    excelData() {
      return this.tableInsurances.map((d) => {
        const tomador = d.persons.find((p) => {
          return p.tomador === true;
        });
        let promotionalcode = "";
        let discount = "0%";
        if (d.promotional) {
          promotionalcode = d.promotional.code + " - id:" + d.promotional.id;
          discount = d.promotional.discount + "%";
        }

        return {
          id: d.id,
          order_code: d.order_code,
          created_at: new Date(d.createdAt).toLocaleDateString('pt-PT'),
          name: tomador ? tomador.name : "",
          surname: tomador ? tomador.surname : "",
          email: tomador ? tomador.email : "",
          mobile_number: tomador ? tomador.mobile_number : "",
          countries: d.countries,
          initial_date: d.initial_date,
          final_date: d.final_date,
          people_quantity: d.people_quantity,
          plan: d.plan,
          status: d.status ? "Apólice enviada" : "Apólice não enviada",
          payment_method: d.payment_method,
          payment_status: d.payment_status,
          price: d.price,
          promotionalcode: promotionalcode,
          discount: discount,
        };
      });
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR');
      }

      return false;
    },
    showAdminOrModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR') || this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
  },
  methods: {
    getBadge (status) {
      return status === 'paid' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'pending' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    },
    getDados(id){
      this.modalInsurances = this.tableInsurances.filter(function(event){
        if(event.id == id){
          return event
        }
      })
      this.largeModal = true
    },
    alterStatusTo0(id){
      InsuranceDataService.changeStatusTo0(id)
      .then(response => {
        if (response.data.message == "success") {
          this.tableInsurances.filter(function(event){
          if(event.id == id){
            event.status = 0 
            }
          })
        }
      })
    },
    alterStatusTo1(id){
      InsuranceDataService.changeStatusTo1(id)
      .then(response => {
        if (response.data.message == "success") {
          this.tableInsurances.filter(function(event){
          if(event.id == id){
            event.status = 1 
            }
          })
        }
      })
    },
    toUpper(str){
        return str ? str.toUpperCase() : ''
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    InsuranceDataService.get()
      .then(response => {
        this.tableInsurances = response.data;

      })
      .catch(e => {
        alert(e);
      });
  }
}
</script>

<style scope="">
  label{
    font-weight: bold;
  }

  .btn-download-excel{
    float: right;
    border: 1px solid #EEE;
    background: #EEE;
    font-size: 11px;
    font-weight: bold;
    transition: 0.2s;
  }

  .btn-download-excel:hover{
    background: #EFE;
    border: 1px solid #ADA;
    color: #0c7238;
    transition: 0.2s;
  }

  .btn-download-excel img{
    width: 24px;
    margin-right: 10px;
    height: auto;
  }
</style>